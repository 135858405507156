.settings-button,
.about-button {
  width: 125px;
  height: 125px;
  position: absolute;
  bottom: 0;
  cursor: pointer;
}

.settings-button {
  border-top-right-radius: 125px;
  left: 0;
  transition: background-color 0.5s ease-out;
}

.about-button {
  border-top-left-radius: 125px;
  right: 0;
  transition: background-color 0.5s ease-out;
}

.button-icon {
  position: absolute;
  bottom: 25px;
  font-size: 48px;
}

.settings-button .button-icon {
  left: 25px;
}

.about-button .button-icon {
  right: 25px;
}

/* SWEET ALERT */

.see-github {
  text-decoration: none;
  font-weight: 700;
  display: flex;
}

/* OPTIONS */
.options-items {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 25px;
}

.options-items:first-child {
  margin-top: 25px;
}

.options-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  width: 100px;
  height: 100px;
  padding: 10px;
}

.options-item .option-icon {
  margin-bottom: 10px;
  font-size: 48px;
}

.active {
  border-radius: 15px;
  width: 100px;
  height: 100px;
  padding: 10px;
  color: white;
}

@media (max-width: 481px) {
  .settings-button,
  .about-button {
    width: 100px;
    height: 100px;
  }

  .button-icon {
    font-size: 40px;
    bottom: 20px;
  }

  .settings-button .button-icon {
    left: 20px;
  }

  .about-button .button-icon {
    right: 20px;
  }
}
